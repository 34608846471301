import React from 'react';
import "./ServicePage.css";

import HomePageMiddle1 from "../../Images/HomePageMiddle1.jpg";
import HomePageMiddle2 from "../../Images/HomePageMiddle2.jpg";
import HomePageMiddle3 from "../../Images/HomePageMiddle3.jpg";
import HomePageMiddle4 from "../../Images/HomePageMiddle4.jpg";

import {Link} from "react-router-dom";


const ServicePage = () =>{
    return(
        <div className="ServicePage">
            <h2>Our Offered Services</h2>

            <div className="ServicePageContent">
                <Link to="/services/website-design" className="Service">
                        <div className="ServiceImg">
                            <img src={HomePageMiddle1} alt="Website Design" />  
                        </div>
                        <h5>Website Design</h5>
                </Link>
                <Link to="/services/website-development" className="Service">
                    <div className="ServiceImg">
                        <img src={HomePageMiddle4} alt="Website Development" />
                    </div>
                    <h5>Website Development</h5>
                </Link>
                <Link to="/services/search-engine-optimization" className="Service">
                    <div className="ServiceImg">
                        <img src={HomePageMiddle2} alt="Search Engine Optimization" /> 
                    </div>
                    <h5>Search Engine Optimization</h5>
                </Link>
                <Link to="/services/industries" className="Service">
                    <div className="ServiceImg">
                         <img src={HomePageMiddle3} alt="Industries" />
                    </div>
                    <h5>Industries We Serve</h5>
                </Link>
            </div>
        </div>
    )
}

export default ServicePage;