import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import HomePageMiddle1 from "../../Images/HomePageMiddle1.jpg";
import HomePageMiddle2 from "../../Images/HomePageMiddle2.jpg";
import HomePageMiddle3 from "../../Images/HomePageMiddle3.jpg";

const HomeMiddle = () =>{

    return(
        <div className="HomeMiddle">
            <div className="HomeMiddleTitle">
                <h4>Our Offered Services</h4>
            </div>
            <AnimatePresence>
                <motion.div
                className="HomeMiddleService"
                whileInView={{animation: "1s ease-out slideInLeft", opacity: "1"}}
                initial={{opacity: "0"}}
                exit={{
                  opacity: 0,
                  transition: { backgroundColor: { delay: 0 }, opacity: { delay: 0 } }
                }}
                transition={{
                    opacity: {delay: 0, duration: 0}, animation: {delay: 0}
                }}
              >
                    <div className="HomeMiddleServiceText">
                        <h6>Website Design and Development</h6>
                        <hr />
                        <p>SSWeb Services wants to help bring your brand to life. We create professional websites that will impress your customers with an easy to navigate design that focuses on accessibility, and responds to all devices, whether your customer is using a desktop, tablet, or smartphone. Our business works hands-on with clients every step throughout the design and development process to deliver a product that fits your needs.</p>
                        <p>Our websites are built from the ground up using React JS to achieve a totally personalized product to fit your brand's image. Your brand is unique, so why settle for a template that any other business could use?</p>
                    </div>
                    <div className="HomeMiddleServiceImg">
                        <img src={HomePageMiddle1} alt="Design and Development"/>
                    </div>
                
                </motion.div>
            </AnimatePresence>
            <AnimatePresence>
                <motion.div
                className="HomeMiddleService"
                id="HomeMiddleService2"
                whileInView={{animation: "1s ease-out slideInRight", opacity: "1"}}
                initial={{opacity: "0"}}
                exit={{
                  opacity: 0,
                  transition: { backgroundColor: { delay: 0 }, opacity: { delay: 0 } }
                }}
                transition={{
                    opacity: {delay: 0, duration: 0}, animation: {delay: 0}
                }}
              >
                    <div className="HomeMiddleServiceText">
                        <h6>Search Engine Optimization</h6>
                        <hr />
                        <p>Enhance your online visibility and drive organic traffic to your website with our expert Search Engine Optimization (SEO) services. Our tailored approach to SEO involves optimizing your website's structure, content, and keywords to align with the latest search engine algorithms. By leveraging proven techniques, we elevate your site's ranking on search engine results pages, ensuring that your business captures the attention of your target audience.</p>
                        <p>Imagine the internet is like a huge library with lots and lots of books. When you want to find a book, you ask the librarian to help you. SEO is like helping your website become a special book that the librarian (or search engine like Google) really likes. This can lead to more traffic from potential leads looking for your services!</p>
                    </div>
                    <div className="HomeMiddleServiceImg">
                        <img src={HomePageMiddle2} alt="Design and Development"/>
                    </div>
                
                </motion.div>
            </AnimatePresence>
            <AnimatePresence>
                <motion.div
                className="HomeMiddleService"
                whileInView={{animation: "1s ease-out slideInLeft", opacity: "1"}}
                initial={{opacity: "0"}}
                exit={{
                  opacity: 0,
                  transition: { backgroundColor: { delay: 0 }, opacity: { delay: 0 } }
                }}
                transition={{
                    opacity: {delay: 0, duration: 0}, animation: {delay: 0}
                }}
              >
                    <div className="HomeMiddleServiceText">
                        <h6>Simplicity, Friendliness, and Communication</h6>
                        <hr />
                        <p>When working with a website developer, you're likely going to run into someone who explains matters in a high-level way that only another developer will understand. It's our goal to break the process down as simply as possible for you to understand. We'll make sure that no questions go unanswered, and be sure to answer them in a way that would make sense to anyone!</p>
                        <p>Communication is our highest priority. We want to help your website exceed your expectations and be exactly how you envision it. That means we work with you every step of the process, and make changes based on your approval. Our job doesn't end until you are fully satisfied with your new website!</p>
                    </div>
                    <div className="HomeMiddleServiceImg">
                        <img src={HomePageMiddle3} alt="Design and Development"/>
                    </div>
                
                </motion.div>
            </AnimatePresence>
            {/* <AnimatePresence>
                <div className="HomeMiddleService">
                    <div className="HomeMiddleServiceText">
                        <h6>Web Design and Development</h6>
                        <hr />
                        <p>SSWeb Services wants to help bring your brand to life. We create professional websites that will impress your customers with an easy to navigate design that focuses on accessibility, and responds to all devices, whether your customer is using a desktop, tablet, or smartphone. Our business works hands-on with clients every step throughout the design and development process to deliver a product that fits your needs.</p>
                        <p>Our websites are built from the ground up using React JS to achieve a totally personalized product to fit your brand's image. Your brand is unique, so why settle for a template that any other business could use?</p>
                    </div>
                    <div className="HomeMiddleServiceImg">
                        <img src={HomePageMiddle1} alt="Design and Development"/>
                    </div>
                </div>
            </AnimatePresence>
            <AnimatePresence>
                <div className="HomeMiddleService" id="HomeMiddleService2">
                    <div className="HomeMiddleServiceText">
                        <h6>Web Accessibility</h6>
                        <hr />
                        <p>Web accessibility aims to address users that suffer from visual, motor, auditory, epileptic, and cognitive impairments. It is the practice of making websites usable for all visitors.</p>
                        <p>Web accessibility is becoming a fundamental part of any website, next to privacy and security. Making your website accessible is not only the ethical thing to do, it also considerably reduces your risk of facing an ADA Lawsuit, and opens up your brand's reach to the staggering 26 percent of the population that suffer from disabilities.</p>
                    </div>
                    <div className="HomeMiddleServiceImg">
                        <img src={HomePageMiddle2} alt="Web Accessibility"/>
                    </div>
                </div>
            </AnimatePresence> */}
        </div>
    )
}

export default HomeMiddle;