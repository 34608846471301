import React from "react";
import Industry from "./Industry.js";
import {Link} from "react-router-dom";

import "./Industries.css";

import architect from "../../Images/Industries/architect.jpg";
import aupair from "../../Images/Industries/aupair.jpg";
import autodetail from "../../Images/Industries/autodetail.jpg";
import carpenter from "../../Images/Industries/carpenter.jpg";
import cleaning from "../../Images/Industries/cleaning.jpg";
import commercialpainter from "../../Images/Industries/commercialpainter.jpg";
import construction from "../../Images/Industries/construction.jpg";
import dance from "../../Images/Industries/dance.jpg";
import daycare from "../../Images/Industries/daycare.jpg";
import dentist from "../../Images/Industries/dentist.jpg";
import electrician from "../../Images/Industries/electrician.jpg";
import eventcoordinator from "../../Images/Industries/eventcoordinator.jpg";
import eventplanner from "../../Images/Industries/eventplanner.jpg";
import financialadvisor from "../../Images/Industries/financialadvisor.jpg";
import flooring from "../../Images/Industries/flooring.jpg";
import florist from "../../Images/Industries/florist.jpg";
import furniturerepair from "../../Images/Industries/furniturerepair.jpg";
import generalportfolio from "../../Images/Industries/generalportfolio.jpg";
import hairsalon from "../../Images/Industries/hairsalon.jpg";
import homecontractor from "../../Images/Industries/homecontractor.jpg";
import homeinspector from "../../Images/Industries/homeinspector.jpg";
import homepainter from "../../Images/Industries/homepainter.jpg";
import homeservices from "../../Images/Industries/homeservices.jpg";
import hvac from "../../Images/Industries/hvac.jpg";
import insuranceagent from "../../Images/Industries/insuranceagent.jpg";
import interiordesigner from "../../Images/Industries/interiordesigner.jpg";
import landscaper from "../../Images/Industries/landscaper.jpg";
import lawyer from "../../Images/Industries/lawyer.jpg";
import locksmith from "../../Images/Industries/locksmith.jpg";
import mover from "../../Images/Industries/mover.jpg";
import musicteacher from "../../Images/Industries/musicteacher.jpg";
import nailsalon from "../../Images/Industries/nailsalon.jpg";
import nanny from "../../Images/Industries/nanny.jpg";
import officecontractor from "../../Images/Industries/officecontractor.jpg";
import pestcontrol from "../../Images/Industries/pestcontrol.jpg";
import petservices from "../../Images/Industries/petservices.jpg";
import photographer from "../../Images/Industries/photographer.jpg";
import piercingstudio from "../../Images/Industries/piercingstudio.jpg";
import plumber from "../../Images/Industries/plumber.jpg";
import pool from "../../Images/Industries/pool.jpg";
import realestate from "../../Images/Industries/realestate.jpg";
import refinishing from "../../Images/Industries/refinishing.jpg";
import roofing from "../../Images/Industries/roofing.jpg";
import solarpanel from "../../Images/Industries/solarpanel.jpg";
import spa from "../../Images/Industries/spa.jpg";
import tattooshop from "../../Images/Industries/tattooshop.jpg";
import travelagent from "../../Images/Industries/travelagent.jpg";
import tutor from "../../Images/Industries/tutor.jpg";
import veterinarian from "../../Images/Industries/veterinarian.jpg";
import wedding from "../../Images/Industries/wedding.jpg";


const Industries = () =>{
    const industrydata = [
        {
            name: "Architects",
            pic: architect,
        },
        {
            name: "Au Pair Services",
            pic: aupair
        },
        {
            name: "Auto Detailing",
            pic: autodetail

        },
        {
            name: "Carpenters",
            pic: carpenter

        },
        {
            name: "Cleaning Services",
            pic: cleaning

        },
        {
            name: "Commercial Painters",
            pic: commercialpainter

        },
        {
            name: "Construction Services",
            pic: construction

        },
        {
            name: "Dance Teachers",
            pic: dance

        },
        {
            name: "Day Care",
            pic: daycare

        },
        {
            name: "Dentists",
            pic: dentist

        },
        {
            name: "Electricians",
            pic: electrician

        },
        {
            name: "Event Coordinators",
            pic: eventcoordinator

        },
        {
            name: "Event Planners",
            pic: eventplanner

        },
        {
            name: "Financial Advisors",
            pic: financialadvisor

        },
        {
            name: "Flooring Services",
            pic: flooring

        },
        {
            name: "Florists",
            pic: florist

        },
        {
            name: "Furniture Repair Services",
            pic: furniturerepair

        },
        {
            name: "General Portfolios",
            pic: generalportfolio

        },
        {
            name: "Hair Salons",
            pic: hairsalon

        },
        {
            name: "Home Contractors",
            pic: homecontractor

        },
        {
            name: "Home Inspectors",
            pic: homeinspector

        },
        {
            name: "Home Painters",
            pic: homepainter

        },
        {
            name: "Home Services",
            pic: homeservices

        },
        {
            name: "HVAC Technicians",
            pic: hvac

        },
        {
            name: "Indepent Insurance Agents",
            pic: insuranceagent

        },
        {
            name: "Interior Designers",
            pic: interiordesigner

        },
        {
            name: "Landscaping Services",
            pic: landscaper

        },
        {
            name: "Lawyers and Law Firms",
            pic: lawyer

        },
        {
            name: "Locksmiths",
            pic: locksmith

        },
        {
            name: "Architects",
            pic: architect

        },
        {
            name: "Movers",
            pic: mover

        },
        {
            name: "Music Teachers",
            pic: musicteacher

        },
        {
            name: "Nail Salons",
            pic: nailsalon

        },
        {
            name: "Nanny Services",
            pic: nanny

        },
        {
            name: "Office Contractors",
            pic: officecontractor

        },
        {
            name: "Pest Control Services",
            pic: pestcontrol

        },
        {
            name: "Pet Services",
            pic: petservices

        },
        {
            name: "Photographers",
            pic: photographer

        },
        {
            name: "Piercing Studios",
            pic: piercingstudio

        },
        {
            name: "Plumbers",
            pic: plumber

        },
        {
            name: "Pool and Hot Tub Services",
            pic: pool

        },
        {
            name: "Realtors and Real Estate Agents",
            pic: realestate

        },
        {
            name: "Refinishing Services",
            pic: refinishing

        },
        {
            name: "Roofing Services",
            pic: roofing

        },
        {
            name: "Solar Energy Businesses",
            pic: solarpanel

        },
        {
            name: "Spas",
            pic: spa

        },
        {
            name: "Tattoo Shops",
            pic: tattooshop

        },
        {
            name: "Travel Agents",
            pic: travelagent

        },
        {
            name: "Tutors",
            pic: tutor

        },
        {
            name: "Veterinarians",
            pic: veterinarian

        },
        {
            name: "Wedding Planners",
            pic: wedding
        }
    ]

    return(
        <div className="Industries">
            <h3>Industries We Serve</h3>
            <p>Is your industry not listed? No problem at all! <Link to="/contact">Contact us</Link> or <Link to="/get-started">Schedule an Appointment</Link> with us today and we'll be happy to see how we can help!</p>
            <div className="IndustryList">
                {industrydata.map(item=>{
                    return(
                        <Industry imgsrc={item.pic} imgname={item.name} />
                    )
                })}
            </div>
        </div>
    )
}

export default Industries;