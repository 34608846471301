import React from "react";
import "./GetStarted.css";

import { InlineWidget } from "react-calendly";


const GetStarted = () =>{
    return(

        <div className="GetStarted">
            <h3>Ready to Get Started?</h3>
            <p id="GetStartedSubtext">Schedule an appointment with me today!</p>
            <div className="Calendar1080">
                <InlineWidget url="https://calendly.com/sswspencer/website-quote" styles={{width: "80%", margin: "0 auto", height: "80vh", overflow: "hidden", paddingTop: "0"}}/>
            </div>
            <div className="Calendar1440">
                <InlineWidget url="https://calendly.com/sswspencer/website-quote" styles={{width: "90%", margin: "0 auto", height: "60vh", overflow: "hidden", paddingTop: "0"}}/>
            </div>
            <div className="Calendar4k">
                <InlineWidget url="https://calendly.com/sswspencer/website-quote" styles={{width: "100%", margin: "0 auto", height: "40vh", overflow: "hidden", paddingTop: "0"}}/>
            </div>
            <div className="Calendar720">
            <InlineWidget url="https://calendly.com/sswspencer/website-quote" styles={{width: "100%", margin: "0 auto", height: "90vh", overflow: "hidden", paddingTop: "0"}}/>
            </div>
            <div className="CalendarTablet">
            <InlineWidget url="https://calendly.com/sswspencer/website-quote" styles={{width: "100%", margin: "0 auto", height: "1300px", overflow: "hidden", paddingTop: "0"}}/>
            </div>
            <p id="CalendarErrorDisclaimer">Calendar not working? <a href="https://calendly.com/sswspencer/website-quote">Click here to schedule!</a></p>
            <div className="GetStartedText">
                <p>Step into digital transformation now to revamp your online presence. Book a personalized appointment to explore a journey of innovative web development, captivating design, and strategic SEO for elevated business success! In our meeting, I'll grasp your business essence and goals, aligning my services with your vision. Witness how I convert your concepts into a dynamic, user-friendly website using the latest trends, ensuring beauty and functionality on all devices. Throughout, ask about my services, methods, and the digital landscape. I'm here to clarify and provide insights for informed choices. You'll exit with valuable insights into website design, development, and SEO's integration for a compelling online identity. Every business is unique, and our solutions adapt to your needs. We'll tailor services to your budget, timeline, and objectives, with a focus on maximizing your return on investment!</p>
            </div>

        </div>
    )
}

export default GetStarted;
