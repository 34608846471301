import React from "react";

import HomePageMiddle2 from "../../Images/HomePageMiddle2.jpg";


const SEO = () =>{
    return(
<div className="ServiceDedicatedPage">
            <div className="ServiceDedicatedLeft">
                <img src={HomePageMiddle2} alt="Website Development" />
            </div>
            <div className="ServiceDedicatedRight">
                <h3>Search Engine Optimization</h3>
                <p>Search Engine Optimization, ofern referred to as SEO, is a digital strategy that plays a pivotal role in enhancing the visbility and discoverability of your website on search engines like Google, Bing, and Yahoo. It's the secret sauce that helps your online presence shine. Here's a closer look at what SEO means for you:</p>
                <p><span className="UnderlineSpan">Visibility in Search Results:</span> When people search for products, services, or information online, they typically start with a search engine. SEO is all about ensuring that your website ranks as high as possible in the search results for relevant keywords, increasing the chances of users finding and visitng your site.</p>
                <p><span className="UnderlineSpan">Organic Traffic:</span> Unlike paid advertising, which requires continuous investment, SEO focuses on organic traffic, meaning users who find your site through natural search results. This not only saves you money, but also builds long-term credibilty and trust with your audience.</p>
                <p><span className="UnderlineSpan">On-Page Optimization:</span> We optimize your website's content, meta titles, descriptions, headings, and images to make it search engine-friendly. This ensures that search engines can understand and index your content effectively.</p>
                <p><span className="UnderlineSpan">Quality Content:</span> Content is king in the SEO realm. We create or refine high-quality, relevant content that not only answers users' questions but also positions you as an authority in your field!</p>
                <p><span className="UnderlineSpan">Technical SEO:</span> Behind the scenes, we work on technical aspects like website speed, mobile-friendliness, and secure connections (SSL) to enhance user experience and meet search engine requirements.</p>
            </div>
        </div>
    )
}


export default SEO;