import React from 'react';

const FAQ = ({ title, content }) => {


  return (
    <div className="faq">
      <div className="faq-title">
        <p>{title}</p>
      </div>
        <div className="faq-content">
          <p>{content}</p>
        </div>
    </div>
  );
};

export default FAQ;