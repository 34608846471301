import React from "react";

import HomePageMain from "../../Images/HomePageMain.jpg";

import {Link} from "react-router-dom";

const HomeTop = () =>{
    
    return(
        <div className="HomeTop">
            <img src={HomePageMain} alt="SSWS Homepage Background" />
            <div className="HomeTopText">
                <div className="HomeTopTextInner">
                    <h1>Fully Custom Websites to Make Your Brand Unique</h1>
                    <hr />
                    <h5>Based in Worcester Massachuestts, we create websites tailored to your brand's needs, focusing on accessibility, responsivity, and user experience. We help small businesses and entrepreneurs stand out from the crowd</h5>
                </div>
            </div>
        </div>
    )
}

export default HomeTop;