import React, {useState, useRef} from "react";

import NavLogo from "../../Images/NavLogo.png";

import {TiHomeOutline} from "react-icons/ti";
import {MdMenuOpen} from "react-icons/md";
import {Link} from "react-router-dom";

import "./Nav.css";

const Nav = () =>{
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const closeTimeout = useRef(null);
    
    const handleDropdownOpen = () =>{
        clearTimeout(closeTimeout.current);
        setDropdownOpen(true)
    };

    const handleDropdownClose = () => {
        closeTimeout.current = setTimeout(() =>{
            setDropdownOpen(false);
        }, 300)
    };

    const handleMouseEnterDropdown = () =>{
        clearTimeout(closeTimeout.current);
    };

    const handleMouseLeaveDropdown = () => {
        closeTimeout.current = setTimeout(() =>{
            setDropdownOpen(false);
        }, 300)
    }


    return (
        <nav>
            <div className="NavBanner">
                <p><span>Worcester, MA 01604</span> | <span>(774) 670-8073</span></p>
            </div>
            <div className="NavMain">
                <div className="NavLeft">
                    <img src={NavLogo} alt="SSWS-Design and Development"/>
                </div>
                <div className="NavMiddle">
                    <Link to="/" id="HomeLink"><TiHomeOutline /></Link>
                    <Link to="/services" onMouseEnter={handleDropdownOpen} onMouseLeave={handleDropdownClose}>Services</Link>
                    <Link to="/about">About</Link>
                    <Link to="/faq">FAQ</Link>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className="NavRight">
                    <Link to="/get-started">Get Started</Link>
                </div>
            </div>
            <div className={`NavDropdown ${dropdownOpen ? 'open' : ''}`} onMouseEnter={handleMouseEnterDropdown} onMouseLeave={handleMouseLeaveDropdown}>
                <Link to="/services/website-design">Website Design</Link>
                <Link to="/services/website-development">Website Development</Link>
                <Link to="/services/search-engine-optimization">Search Engine Optimization</Link>
                <Link to="/services/industries">Industries We Serve</Link>
            </div>

            <div className="TabletNav">
                <div className="TabletNavTop">
                    <div className="TabletNavTopImg">
                        <Link to="/"><img src={NavLogo} alt="SSWS-Design and Development" /></Link>
                    </div>
                    <button className="TabletMenuToggle" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>{<MdMenuOpen />}</button>
                </div>
                <div className={`TabletNavMenu ${dropdownOpen ? 'open' : ''}`}>
                    <div className="TabletNavBottom">
                        <Link to="/services" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>Services</Link>
                        <Link to="/about" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>About</Link>
                        <Link to="/faq" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>FAQ</Link>
                        <Link to="/contact" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>Contact</Link>
                        <Link id="MobileGetStarted" to="/get-started" onClick={()=>{setDropdownOpen(!dropdownOpen)}}>Get Started</Link>
                    </div>
                    <div className="TabletNavBanner">
                        <p><span>Worcester, MA 01604</span> | <span>(774) 670-8073</span></p>
                    </div>
                    <div id="MobileNavOverlay" onClick={()=>{setDropdownOpen(!dropdownOpen)}}></div>
                    <div id="TabletGetStarted">
                        <Link to="/get-started">Get Started</Link>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Nav;
