import React from "react";

import HomePageMiddle4 from "../../Images/HomePageMiddle4.jpg";


const WebsiteDevelopment = () =>{
    return(
        <div className="ServiceDedicatedPage">
            <div className="ServiceDedicatedLeft">
                <img src={HomePageMiddle4} alt="Website Development" />
            </div>
            <div className="ServiceDedicatedRight">
                <h3>Website Development</h3>
                <p>Website development is the science of building your online presence. It's the process of taking your ideas, brand, or business, and translating them into a functional website that the world can access. Here's a deeper look at what website development means for you:</p>
                <p><span className="UnderlineSpan">A Digital Canvas for Your Vision:</span> Think of your website as a blank canvas ready to showcase your unique identity. Website developemnt is the creative process that turns your vision, whether it's a personal blog, e-commerce store, or a corporate website, into a vibrant online reality.</p>
                <p><span className="UnderlineSpan">Functionality and Interactivity:</span> Website development ensures that your site functions flawlessly. We build features and interactive elements that cater to your specific needs, whether it's online forms, e-commerce shopping carts, or user registration systems</p>
                <p><span className="UnderlineSpan">Accessibility and Responsiveness:</span> In today's mobile-centric world, your website must be accessible on various devices and screen sizes. We make sure that your site is responsive, adapting gracefully to smartphones, tablets, and desktops for a consistent user experience</p>
                <p><span className="UnderlineSpan">Ongoing Support and Maintenance:</span> Website development isn't a one-time endeavor. We provide continuous support, ensuring your website remains up-to-date, secure, and dunctional. From software updates to troubleshooting, we've got you covered!</p>
            </div>
        </div>
    )
}


export default WebsiteDevelopment;