import React from "react";

import HomePageMiddle1 from "../../Images/HomePageMiddle1.jpg";


const WebsiteDesign = () =>{
    return(
        <div className="ServiceDedicatedPage">
            <div className="ServiceDedicatedLeft">
                <img src={HomePageMiddle1} alt="Website Design" />
            </div>
            <div className="ServiceDedicatedRight">
                <h3>Website Design</h3>
                <p>Website design is the artistic and strategic process of creating the visual elements, layout, and user interface of a website. It's the aesthetic and functional heart of your online presence. Here's a comprehensive look at what website design means for you:</p>
                <p><span className="UnderlineSpan">Visual Identity:</span> Your website design is like your digital business card. It communicates your brand's personality and identity to visitors. We craft designs that align with your brand's colors, logos, and overall aesthetics, ensuring a cohesive and memorable online presence.</p>
                <p><span className="UnderlineSpan">User-Centric Approach:</span> User experience (UX) is at the core of our design philosophy. We focus on making your website user-friendly and intuitive, creating a seamless and enjoyable journey for your visitors.</p>
                <p><span className="UnderlineSpan">Layout and Structure:</span> The layout of your website determines how content is organized and presented. We carefully plan the placement of elements like headers, menus, and content blocks to guide visitors and keep them engaged.</p>
                <p><span className="UnderlineSpan">Graphics and Multimedia:</span> Engaging visuals, images, videos, and animations can enhance the user experience and convey information more effectively. We integrate multimedia elements strategically to captivate and inform your audience!</p>
                <p><span className="UnderlineSpan">Collaboration:</span> We value your input and work closely with you throughout the design process. Your feedback and ideas are vital in creating a design that aligns perfectly with your vision and goals!</p>
            </div>
        </div>
    )
}


export default WebsiteDesign;