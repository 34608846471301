import React from "react";
import "./SalesBanner.css";
import {Link} from "react-router-dom";

const SalesBanner = () =>{
    return(
        <div className="SalesBanner">
            <h3>Ready to take the next steps for yourself and your business?</h3>
            <Link to="/get-started">Schedule your free quote today!</Link>
        </div>
    )
}

export default SalesBanner;