import React from "react";
import "./Footer.css";
import NavLogo from "../../Images/NavLogo.png";

import {Link} from "react-router-dom";

import {AiOutlineInstagram, AiOutlineFacebook, AiOutlineLinkedin} from "react-icons/ai";

const Footer = () =>{
    return(
        <div className="Footer">
            <div className="FooterTop">
                <div className="FooterLeft">
                    <img src={NavLogo} alt="SS Web Services" />
                    <div className="FooterLeftLinks">
                        <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><AiOutlineFacebook/></a>
                        <a href="https://www.instagram.com/sswspencer/" target="_blank" rel="noreferrer"><AiOutlineInstagram/></a>
                        <a href="https://www.linkedin.com/in/steven-sw-spencer" target="_blank" rel="noreferrer"><AiOutlineLinkedin/></a>
                    </div>
                </div>
                <div className="FooterRight">
                    <Link to="/">Home</Link>
                    <Link to="/services">Services</Link>
                    <Link to="/about">About</Link>
                    <Link to="/services/industries">Industries</Link>
                    <Link to="/about/portfolio">Portfolio</Link>

                </div>
            </div>
            <div className="FooterBottom">
                <p>2020-2023 Steven Spencer Web Services • <Link to="/terms-and-conditions">Terms and Conditions</Link> • <Link to="/privacy-policy">Privacy Policy</Link> • <Link to="/attributions">Attributions</Link></p>
            </div>
        </div>
    )
}

export default Footer;