import React from "react";

import "./Attributions.css"

const Attributions = () =>{
    return(
        <div className="Attributions">
            <h3>Attributions</h3>
            <p>As a sole proprietor and small business, I rely greatly on stock photographers who are generous enough to release their work to the public domain. While not required, I wanted to add a section on here thanking these people for the work they do. All images on this site are protected under the <a href="https://unsplash.com/license" target="_blank" rel="noreferrer">Unslash License</a> and I encourage you to check these awesome people out! (Please note that some images may be slightly edited for thematic, styling, or sizing reasons)</p>
            <h5 className="PageTitle">Home Page</h5>
            <div className="AttributionList">
                <div className="Attribution">
                    <p>Main Background Image</p>
                    <a href="https://unsplash.com/@linusmimietz" target="_blank" rel="noreferrer">Linus Mimietz</a>
                    <a href="https://unsplash.com/photos/gvptKmonylk" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Web Design and Development" Image</p>
                    <a href="https://unsplash.com/@domenicoloia" target="_blank" rel="noreferrer">Domenico Loia</a>
                    <a href="https://unsplash.com/photos/hGV2TfOh0ns" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Search Engine Optimization" Image</p>
                    <a href="https://unsplash.com/@edhoradic" target="_blank" rel="noreferrer">Edho Pratama</a>
                    <a href="https://unsplash.com/photos/yeB9jDmHm6M" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"S, F, & C" Image</p>
                    <a href="https://unsplash.com/@cytonn_photography" target="_blank" rel="noreferrer">Cytonn Photography</a>
                    <a href="https://unsplash.com/photos/n95VMLxqM2I" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
            </div>

            <h5 className="PageTitle">Services Main Page</h5>
            <div className="AttributionList">
                <div className="Attribution">
                    <p>"Website Design" Image</p>
                    <a href="https://unsplash.com/@domenicoloia" target="_blank" rel="noreferrer">Domenico Loia</a>
                    <a href="https://unsplash.com/photos/hGV2TfOh0ns" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Website Development" Image</p>
                    <a href="https://unsplash.com/@silvawebdesigns" target="_blank" rel="noreferrer">Nathan da Silva</a>
                    <a href="https://unsplash.com/photos/FO7kUmBYVi0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Search Engine Optimization" Image</p>
                    <a href="https://unsplash.com/@edhoradic" target="_blank" rel="noreferrer">Edho Pratama</a>
                    <a href="https://unsplash.com/photos/yeB9jDmHm6M" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Industries We Serve" Image</p>
                    <a href="https://unsplash.com/@cytonn_photography" target="_blank" rel="noreferrer">Cytonn Photography</a>
                    <a href="https://unsplash.com/photos/n95VMLxqM2I" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
            </div>
            <h5 className="PageTitle">Services: "Industries We Serve"</h5>
            <div className="AttributionList">
                <div className="Attribution">
                    <p>"Architects" Image</p>
                    <a href="https://unsplash.com/@ryanancill" target="_blank" rel="noreferrer">Ryan Ancill</a>
                    <a href="https://unsplash.com/photos/Vew4xyLkpdY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Au Pair Services" Image</p>
                    <a href="https://unsplash.com/@dylan_nolte" target="_blank" rel="noreferrer">Dylan Nolte</a>
                    <a href="https://unsplash.com/photos/HNXi5znlb8U" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Auto Detailing" Image</p>
                    <a href="https://unsplash.com/@clement_roy" target="_blank" rel="noreferrer">Clément ROY</a>
                    <a href="https://unsplash.com/photos/Kky_GwoF4_k" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Carpenters" Image</p>
                    <a href="https://unsplash.com/@ryno_marais" target="_blank" rel="noreferrer">Ryno Marais</a>
                    <a href="https://unsplash.com/photos/p5JcD-_13ek" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Cleaning" Image</p>
                    <a href="https://unsplash.com/@cdc" target="_blank" rel="noreferrer">CDC</a>
                    <a href="https://unsplash.com/photos/jjrXvzbqC5E" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Commercial Painter" Image</p>
                    <a href="https://unsplash.com/@seffen99" target="_blank" rel="noreferrer">Sven Brandsma</a>
                    <a href="https://unsplash.com/photos/kerFMg52cUA" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Construction" Image</p>
                    <a href="https://unsplash.com/@jeriden94" target="_blank" rel="noreferrer">Jeriden Villegas</a>
                    <a href="https://unsplash.com/photos/VLPUm5wP5Z0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Dance Teachers" Image</p>
                    <a href="https://unsplash.com/@rainierridao" target="_blank" rel="noreferrer">Rainier Ridao</a>
                    <a href="https://unsplash.com/photos/GRDpPpKczdY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Day Care" Image</p>
                    <a href="https://unsplash.com/@cdc" target="_blank" rel="noreferrer">CDC</a>
                    <a href="https://unsplash.com/photos/gsRi9cWCIB0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Dentists" Image</p>
                    <a href="https://unsplash.com/@carocaro1987" target="_blank" rel="noreferrer">Caroline LM</a>
                    <a href="https://unsplash.com/photos/-m-4tYmtLlI" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Electricians" Image</p>
                    <a href="https://unsplash.com/@emmages" target="_blank" rel="noreferrer">Emmanuel Ikwuegbu</a>
                    <a href="https://unsplash.com/photos/_2AlIm-F6pw" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Event Coordinators" Image</p>
                    <a href="https://unsplash.com/@aranxa_esteve" target="_blank" rel="noreferrer">Aranxa Esteve</a>
                    <a href="https://unsplash.com/photos/S5DEUg2yUVU" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Event Planners" Image</p>
                    <a href="https://unsplash.com/@jazminantoinette" target="_blank" rel="noreferrer">Jazmin Quaynor</a>
                    <a href="https://unsplash.com/photos/18mUXUS8ksI" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Financial Advisors" Image</p>
                    <a href="https://unsplash.com/@chrisliverani" target="_blank" rel="noreferrer">Chris Liverani</a>
                    <a href="https://unsplash.com/photos/NDfqqq_7QWM" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Flooring" Image</p>
                    <a href="https://unsplash.com/@ernys" target="_blank" rel="noreferrer">Ernys</a>
                    <a href="https://unsplash.com/photos/EO_KlX-A6SY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Florists" Image</p>
                    <a href="https://unsplash.com/@waldemarbrandt67w" target="_blank" rel="noreferrer">Waldemar</a>
                    <a href="https://unsplash.com/photos/q3RGXuBc_SU" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Furniture Repair" Image</p>
                    <a href="https://unsplash.com/@www_erzetich_com" target="_blank" rel="noreferrer">Blaz Erzetic</a>
                    <a href="https://unsplash.com/photos/hi3Hk33Hqlc" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"General Portfolios" Image</p>
                    <a href="https://unsplash.com/@icons8" target="_blank" rel="noreferrer">Icons8 Team</a>
                    <a href="https://unsplash.com/photos/FcLyt7lW5wg" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"HVAC Technicians" Image</p>
                    <a href="https://unsplash.com/@vakerbv" target="_blank" rel="noreferrer">Vadim Babenko</a>
                    <a href="https://unsplash.com/photos/IUuVB1nLsQU" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Hair Salons" Image</p>
                    <a href="https://unsplash.com/@awcreativeut" target="_blank" rel="noreferrer">Adam Winger</a>
                    <a href="https://unsplash.com/photos/WXmHwPcFamo" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Home Painters" Image</p>
                    <a href="https://unsplash.com/@themephotos" target="_blank" rel="noreferrer">Theme Photos</a>
                    <a href="https://unsplash.com/photos/Cl-OpYWFFm0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>  
                <div className="Attribution">
                    <p>"Home Contractors" Image</p>
                    <a href="https://unsplash.com/@commonboxturtle" target="_blank" rel="noreferrer">Connor Brown</a>
                    <a href="https://unsplash.com/photos/TWxNr5ZpxJM" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Home Inspectors" Image</p>
                    <a href="https://unsplash.com/@jjying" target="_blank" rel="noreferrer">JJ Ying</a>
                    <a href="https://unsplash.com/photos/jD5RVR9BAS8" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Home Services" Image</p>
                    <a href="https://unsplash.com/@anniegray" target="_blank" rel="noreferrer">Annie Gray</a>
                    <a href="https://unsplash.com/photos/WEWTGkPUVT0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Independent Insurance Agents" Image</p>
                    <a href="https://unsplash.com/@austindistel" target="_blank" rel="noreferrer">Austin Distel</a>
                    <a href="https://unsplash.com/photos/jpHw8ndwJ_Q" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Interior Designers" Image</p>
                    <a href="https://unsplash.com/@spacejoy" target="_blank" rel="noreferrer">Spacejoy</a>
                    <a href="https://unsplash.com/photos/9M66C_w_ToM" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Landscapers" Image</p>
                    <a href="https://unsplash.com/@fanofthe5" target="_blank" rel="noreferrer">Jared Muller</a>
                    <a href="https://unsplash.com/photos/EkhWxU_pgLo" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Legal Offices and Law Firms" Image</p>
                    <a href="https://unsplash.com/@tingeyinjurylawfirm" target="_blank" rel="noreferrer">Tingey Injury Law Firm</a>
                    <a href="https://unsplash.com/photos/L4YGuSg0fxs" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Locksmiths" Image</p>
                    <a href="https://unsplash.com/@imattsmart" target="_blank" rel="noreferrer">iMattSmart</a>
                    <a href="https://unsplash.com/photos/Vp3oWLsPOss" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Movers" Image</p>
                    <a href="https://unsplash.com/@markusspiske" target="_blank" rel="noreferrer">Markus Spiske</a>
                    <a href="https://unsplash.com/photos/RWTUrJf7I5w" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Music Teachers" Image</p>
                    <a href="https://unsplash.com/@jakaylatoney" target="_blank" rel="noreferrer">Jakayla Toney</a>
                    <a href="https://unsplash.com/photos/oHaAiMk-9xY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Nail Salons" Image</p>
                    <a href="https://unsplash.com/@sweetspotphoto" target="_blank" rel="noreferrer">Jesse Donoghoe</a>
                    <a href="https://unsplash.com/photos/2aiP_wxNrfU" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Nanny Services" Image</p>
                    <a href="https://unsplash.com/@krakenimages" target="_blank" rel="noreferrer">krakenimages</a>
                    <a href="https://unsplash.com/photos/OroUwAwLs-8" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Office Contractors" Image</p>
                    <a href="https://unsplash.com/@beccatapert" target="_blank" rel="noreferrer">Becca Tapert</a>
                    <a href="https://unsplash.com/photos/tam80nXFvL4" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Pest Control" Image</p>
                    <a href="https://unsplash.com/@arjun_mj" target="_blank" rel="noreferrer">Arjun MJ</a>
                    <a href="https://unsplash.com/photos/792-GkRUtes" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Pet Services" Image</p>
                    <a href="https://unsplash.com/@jonasvincentbe" target="_blank" rel="noreferrer">Jonas Vincent</a>
                    <a href="https://unsplash.com/photos/xulIYVIbYIc" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Photographers" Image</p>
                    <a href="https://unsplash.com/@jakobowens1" target="_blank" rel="noreferrer">Jakob Owens</a>
                    <a href="https://unsplash.com/photos/DQPP9rVLYGQ" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Piercing Studios" Image</p>
                    <a href="https://unsplash.com/@figmentprints" target="_blank" rel="noreferrer">Cat Han</a>
                    <a href="https://unsplash.com/photos/7EaQT4M8aFc" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Plumbers" Image</p>
                    <a href="https://unsplash.com/@gottapics" target="_blank" rel="noreferrer">Marian Florinel Condruz</a>
                    <a href="https://unsplash.com/photos/C-oYJoIfgCs" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Pool and Hot Tub" Image</p>
                    <a href="https://unsplash.com/@claritycontrol" target="_blank" rel="noreferrer">Joe Ciciarelli</a>
                    <a href="https://unsplash.com/photos/08AJKJf75kw" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Real Estate Agents and Realtors" Image</p>
                    <a href="https://unsplash.com/@tierramallorca" target="_blank" rel="noreferrer">Tierra Mallorca</a>
                    <a href="https://unsplash.com/photos/rgJ1J8SDEAY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Refinishing Services" Image</p>
                    <a href="https://unsplash.com/@bernardhermant" target="_blank" rel="noreferrer">Bernard Hermant</a>
                    <a href="https://unsplash.com/photos/XFCei-F4mjg" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Roofers" Image</p>
                    <a href="https://unsplash.com/@razesolar" target="_blank" rel="noreferrer">Raze Solar</a>
                    <a href="https://unsplash.com/photos/G8tyblQ4p04" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Solar Energy Businesses" Image</p>
                    <a href="https://unsplash.com/@wmmead" target="_blank" rel="noreferrer">Bill Mead</a>
                    <a href="https://unsplash.com/photos/wmaP3Tl80ww" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Spa" Image</p>
                    <a href="https://unsplash.com/@caishan119" target="_blank" rel="noreferrer">Alan Caishan</a>
                    <a href="https://unsplash.com/photos/cU53ZFBr3lk" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Tattoo Shops" Image</p>
                    <a href="https://unsplash.com/@shadrinaphotography" target="_blank" rel="noreferrer">Tetiana Shadrina</a>
                    <a href="https://unsplash.com/photos/EeuQVx1WlOA" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Travel Agents" Image</p>
                    <a href="https://unsplash.com/@nicolegeri" target="_blank" rel="noreferrer">Nicole Geri</a>
                    <a href="https://unsplash.com/photos/gMJ3tFOLvnA" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Tutors" Image</p>
                    <a href="https://unsplash.com/@sofatutor" target="_blank" rel="noreferrer">sofatutor</a>
                    <a href="https://unsplash.com/photos/4r5Hogjbgkw" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Veterinary Services" Image</p>
                    <a href="https://unsplash.com/@priscilladupreez" target="_blank" rel="noreferrer">Priscilla Du Preez</a>
                    <a href="https://unsplash.com/photos/2hc6ocDAsNY" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
                <div className="Attribution">
                    <p>"Wedding Planners" Image</p>
                    <a href="https://unsplash.com/@ohhbee" target="_blank" rel="noreferrer">Olivia Bauso</a>
                    <a href="https://unsplash.com/photos/30UOqDM5QW0" target="_blank" rel="noreferrer">Original Photo</a>
                </div>
            </div>
        </div>
    )
}

export default Attributions;