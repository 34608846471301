import './App.css';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import ScrollToTop from "./Components/ScrollToTop.js";
import Nav from "./Components/Nav/Nav.js";
import NavSpacingBlock from "./Components/Nav/NavSpacingBlock.js";
import HomePage from "./Components/HomePage/HomePage.js";
import ServicePage from "./Components/ServicePage/ServicePage.js";
import WebsiteDesign from "./Components/ServicePage/WebsiteDesign.js";
import WebsiteDevelopment from "./Components/ServicePage/WebsiteDevelopment.js";
import SEO from "./Components/ServicePage/SEO.js";
import Industries from "./Components/Industries/Industries.js";
import About from "./Components/About/About.js";
import FAQPage from "./Components/FAQ/FAQPage.js";
import Contact from "./Components/Contact/Contact.js";
import SalesBanner from "./Components/SalesBanner/SalesBanner.js";
import GetStarted from "./Components/GetStarted/GetStarted.js";
import Footer from "./Components/Footer/Footer.js";
import Legal from "./Components/Legal/Legal.js";
import TandC from "./Components/Legal/TandC.js"
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy.js";
import Attributions from "./Components/Attributions/Attributions.js"


function App() {
  return (
    <Router>
      <div className="AppWrapper">
        <ScrollToTop/>
        <Nav />
        <NavSpacingBlock />
        <Routes>

          <Route path="/" element={<HomePage />} /> 
          <Route path="/services" element={<ServicePage/>} />
          <Route path="/services/website-design" element={<WebsiteDesign/>} />
          <Route path="/services/website-development" element={<WebsiteDevelopment/>} />
          <Route path="/services/search-engine-optimization" element={<SEO/>} />
          <Route path="/services/industries" element={<Industries />} />
          <Route path="/about" element={<About/>}/>
          <Route path="/faq" element={<FAQPage />}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/get-started" element={<GetStarted/>} />
          <Route path="/legal" element={<Legal/>} />
          <Route path="/terms-and-conditions" element={<TandC/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/attributions" element={<Attributions />} />
        </Routes>
        <SalesBanner />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
