import React from "react";

import {Link} from "react-router-dom";

import "./Legal.css";
const Legal = () =>{

    return(
        <div className="LegalNav">
            <Link to="/terms-and-conditions"><h3>Terms and Conditions</h3></Link>
            <Link to="/privacy-policy"><h3>Privacy Policy</h3></Link>
        </div>
    )
}

export default Legal;