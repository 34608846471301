import React from "react";

import HomeTop from "./HomeTop.js";
import HomeMiddle from "./HomeMiddle.js";

import "./HomePage.css";

const HomePage = () =>{
    return(
        <div className="HomeWrapper">
            <HomeTop />
            <HomeMiddle />
        </div>
    )
}

export default HomePage;