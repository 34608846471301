import React from "react";
import {useLocation} from "react-router-dom";
import "./Nav.css";

const NavSpacingBlock = () =>{
    const location = useLocation()

    if(location.pathname === "/") {
    return null
}
    return(
        <div className="NavSpacingBlock">
            <h1>.</h1>
        </div>
    )
}

export default NavSpacingBlock;