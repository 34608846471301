import React from "react";

import {Link} from "react-router-dom";

const Industry = props => {
    return(
        <Link to="/get-started" className="Industry">
            <div className="IndustryImg">
                <img src={props.imgsrc} alt={props.imgname} />
            </div>
            <h6>{props.imgname}</h6>
        </Link>
    )

}

export default Industry;