import React from "react";
import SSWS from "../../Images/SSWS.jpg";
import Hiro from "../../Images/Hiro.jpg";

import "./About.css";

const About = () => {
    return(
        <div className="About">

            <div className="AboutSection">
                <div className="AboutSectionImg">
                    <img src={SSWS} alt="Steven Spencer" />
                    <div className="AboutSectionImgTitle">
                        <h5>Steven Spencer</h5>
                        <h6>Sole Proprietor, Developer</h6>
                    </div>
                </div>
                <div className="AboutSectionText">
                    <h3>Meet Steven</h3>
                    <p>Welcome to my corner of the web! I'm Steven, your dedicated partner in bringing your digital dreams to life. With a passion for code and a knack for design, I've spent years honing my skills in the ever-evolving world of website development.</p>
                    <p>My journey began when I was just a high school student looking for any reason to use a computer. Three years of Java classes later I moved my studies into the website development realm, and have since evolved into a full-fledged career dedicated to crafting seamless and visually stunning online experiences. I've had the privilege of working with clients to help them transform their online presence and achieve their goals.</p>
                    <p>My approach to website development is rooted in the belief that every project is unique. I take the time to truly understnad your vision and objectives, ensuring that each website I create is not just a template, but a tailored solution designed to captivate your audience and drive results. Whether you're looking for a sleek and modern design, e-commerce functionality, or a user-friendly interface, I've got you covered!</p>
                    <p>What sets me apart is my commitment to staying at the forefront of industry trends and technologies. In a rapidly changing digital landscape, I make it my mission to provude you with cutting-edge solutions taht keep your website adead of the curve. From responsive design to SEO optimization, I leave no stone unturned in delivering websites that not only look great but also perform exceptionally well.</p>
                    <p>When I'm not immersed in lines of code, you can find me exploring the latest tech innovations, experimenting with new design concepts, or simply hanging out with other developers while brainstorming ideas for my next project.</p>
                    <p>I'm thrilled to embark on a website development journey with you, and I can't wait to help you turn your vision into a reality. Let's collaborate, create, and make your online presence truly remarkable!</p>
                </div>
            </div>

            <div className="AboutSection AboutSection2">
                <div className="AboutSectionImg">
                    <img src={Hiro} alt="Hiro" />
                    <h5>Hiro</h5>
                    <h6>Head of Barketing</h6>
                </div>
                <div className="AboutSectionText">
                    <h3>Meet Hiro</h3>
                    <p>Woof! I'm Hiro, the furry mastermind behind the scenes at SS Web Services. As the Head of Barketing, I take my role in this web development adventure quite seriously! Steven tells me time and time again that we're in this 50/50, and I'm not just on this page to appeal to the dog crowd or justify his incessant use of "we" when referring to his sole proprietorship.</p>
                    <p>While my human companion handles the technical wizardry, I'm here to provide the pawsitive energy and endless enthusiasm that keeps the creative jusices flowing. My daily responsibilities include barking at bugs, chasing my tail (for inspiration, of course), and offering morale-boosts during those tough coding sessions.</p>
                    <p>I may not be fluent in any programming language, but I am fluent in tail wags, which I deploy strategically to boost our team's spirits. Need a break from the screen? I'm always up for a game of fetch or a quick walk to recharge your batteries!</p>
                    <p>But don't be fooled by my fluffy exterior; I've not a keen eye for design and a nose for quality. If a website's user experience doesn't pass the belly rub test, you can be I'll let my humans know!</p>
                    <p>When I'm not crunching imaginary numbers or chasing my tail in pursuit of the ultimate marketing idea, you can find me hiding under the couch, sunbathing in the office, or guarding the treat jar with unwavering determination.</p>
                    <p>So whether you're here for top-notch website development, or just to see some adorable doggy photos, know that I'm here to make your visit a tail-wagging success! Thanks for stopping by, and don't hesitate to reach out if you need anything. Woof!</p>
                </div>
            </div>
        </div>
    )
}

export default About;